import { useTranslation } from "react-i18next";
import "../../translations/i18n";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form, Col, Button, Row } from "react-bootstrap";
import { FieldArray, Formik } from "formik";
import * as Yup from "yup";
import { selectList as selectGamesList } from "../games/game.selectors";
import { selectList as selectProvidersList } from "../serverProviders/serverProvider.selectors";
import { GameData, getList as getGamesList } from "../games/game.slice";
import {
    ServerProviderData,
    getList as getProvidersList
} from "../serverProviders/serverProvider.slice";
import { AppDispatch } from "../../shared/store";
import { setGeneral } from "./licenseApplication.slice";
import {
    crossSmallIcon,
    chevronRightIcon,
    requiredFieldIcon
} from "../../shared/constants/unicodeIcons";
import {
    getDefaultLocale,
    getLanguage,
    getLocalesList
} from "../../shared/utils/languageUtil";
import DetectCompletedForm from "../../shared/components/DetectCompletedForm";
import BeforeUnloadFormWarning from "../../shared/components/BeforeUnloadFormWarning";
import { ipAddressPattern } from "../../shared/constants/regex";
import { FormStep } from "../../pages/ApplicationPage";
import { GeneralData } from "../servers/server.slice";
import {
    maxLengthExtraLarge,
    maxLengthLarge,
    maxSizeNumber,
    minAdmins,
    minPlayerSlots,
    maxPlayerSlots,
    maxLocales
} from "../../shared/constants/validation";

const GeneralForm: React.FC<FormStep> = ({
    completedHandler,
    nextStepHandler
}) => {
    const { t: translate } = useTranslation();
    const dispatch: AppDispatch = useDispatch();
    const games = useSelector(selectGamesList);
    const providers = useSelector(selectProvidersList);
    const [licenseAgreementUrl, setLicenseAgreementUrl] = useState("");
    const allLocales = [...getLocalesList()];

    useEffect(() => {
        dispatch(getGamesList());
        dispatch(getProvidersList());
    }, [dispatch]);

    const schema = Yup.object().shape({
        gameId: Yup.string().required(translate("generalForm.gameRequired")),
        acceptedTerms: Yup.boolean().oneOf(
            [true],
            translate("generalForm.acceptTermsRequired")
        ),
        serverProviderId: Yup.string().required(
            translate("generalForm.serverProviderRequired")
        ),
        name: Yup.string().required(
            translate("generalForm.serverNameRequired")
        ),
        availableAdmins: Yup.number()
            .integer(translate("common.integerRequired"))
            .required(translate("generalForm.availableAdminsRequired"))
            .moreThan(minAdmins, translate("generalForm.availableAdminsUnder"))
            .lessThan(
                maxSizeNumber,
                translate("generalForm.availableAdminsOver")
            ),
        ipAddress: Yup.string()
            .required(translate("generalForm.ipAddressRequired"))
            .matches(
                ipAddressPattern,
                translate("generalForm.ipAddressInvalid")
            ),
        playerSlots: Yup.number()
            .integer(translate("common.integerRequired"))
            .required(translate("generalForm.playerSlotsRequired"))
            .moreThan(
                minPlayerSlots - 1,
                translate("generalForm.playerSlotsUnder")
            )
            .lessThan(
                maxPlayerSlots + 1,
                translate("generalForm.playerSlotsOver")
            ),
        locales: Yup.array()
            .min(1, translate("generalForm.localesUnder"))
            .max(maxLocales, translate("generalForm.localesOver"))
    });

    const initialValues: GeneralData = {
        gameId: "",
        gameName: "",
        acceptedTerms: false,
        serverProviderId: "",
        serverProviderName: "",
        hardwareProfileRequired: false,
        name: "",
        ipAddress: "",
        locales: [getDefaultLocale()],
        playerSlots: 0,
        availableAdmins: 0,
        additionalComments: ""
    };

    const submitHandler = (values: GeneralData) => {
        const provider = providers?.find(
            (item: ServerProviderData) => item.id === values.serverProviderId
        );
        dispatch(
            setGeneral({
                ...values,
                serverProviderName: provider?.name || "",
                hardwareProfileRequired: !!provider?.hardwareProfileRequired
            })
        );
        if (nextStepHandler) {
            nextStepHandler();
        }
    };

    return (
        <div>
            <h5>{translate("generalForm.title")}</h5>
            <p>
                {translate("common.requiredFieldInfo", {
                    icon: requiredFieldIcon
                })}
            </p>
            <Formik
                validationSchema={schema}
                initialValues={initialValues}
                onSubmit={submitHandler}
            >
                {({
                    handleSubmit,
                    handleChange,
                    handleBlur,
                    setFieldValue,
                    values,
                    touched,
                    errors
                }) => (
                    <Form noValidate onSubmit={handleSubmit}>
                        <DetectCompletedForm
                            completedHandler={completedHandler}
                        />
                        <BeforeUnloadFormWarning />
                        <Row>
                            <Form.Group
                                className="mb-2"
                                as={Col}
                                md
                                controlId="generalForm.gameId"
                            >
                                <Form.Label>
                                    {translate("generalForm.game")}
                                    {requiredFieldIcon}
                                </Form.Label>
                                {games && games.length === 0 && (
                                    <div>{translate("common.loading")}</div>
                                )}
                                {games &&
                                    games.length > 0 &&
                                    games
                                        .filter(
                                            (item: GameData) => item.enabled
                                        )
                                        .map((item: GameData) => (
                                            <Form.Check
                                                key={item.id}
                                                label={item.name}
                                                name="gameId"
                                                type="radio"
                                                id={item.id}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={item.id}
                                                isInvalid={
                                                    !!touched.gameId &&
                                                    !!errors.gameId
                                                }
                                                onClick={() => {
                                                    setLicenseAgreementUrl(
                                                        item.licenseAgreementUrl
                                                    );
                                                    setFieldValue(
                                                        "gameName",
                                                        item.name
                                                    );
                                                }}
                                            />
                                        ))}
                                <Form.Control.Feedback
                                    type="invalid"
                                    className={
                                        errors.gameId && touched.gameId
                                            ? "d-block"
                                            : ""
                                    }
                                >
                                    {errors.gameId}
                                </Form.Control.Feedback>
                                <div
                                    className="mt-1"
                                    hidden={!licenseAgreementUrl}
                                >
                                    <Form.Check
                                        label={
                                            <span>
                                                {translate(
                                                    "generalForm.acceptTerms"
                                                )}{" "}
                                                <a
                                                    target="blank"
                                                    href={licenseAgreementUrl}
                                                >
                                                    {translate(
                                                        "generalForm.agreement"
                                                    )}
                                                </a>
                                            </span>
                                        }
                                        name="acceptedTerms"
                                        type="checkbox"
                                        id={licenseAgreementUrl}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        defaultChecked={values.acceptedTerms}
                                        isInvalid={
                                            !!touched.acceptedTerms &&
                                            !!errors.acceptedTerms
                                        }
                                    />
                                    <Form.Control.Feedback
                                        type="invalid"
                                        className={
                                            errors.acceptedTerms &&
                                            touched.acceptedTerms
                                                ? "d-block"
                                                : ""
                                        }
                                    >
                                        {errors.acceptedTerms}
                                    </Form.Control.Feedback>
                                </div>
                            </Form.Group>
                            <Form.Group
                                className="mb-2"
                                as={Col}
                                md
                                controlId="generalForm.serverProviderId"
                            >
                                <Form.Label>
                                    {translate("generalForm.serverProvider")}
                                    {requiredFieldIcon}
                                </Form.Label>
                                <Form.Control
                                    aria-label="server-provider-select"
                                    as="select"
                                    name="serverProviderId"
                                    value={values.serverProviderId}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    isInvalid={
                                        !!touched.serverProviderId &&
                                        !!errors.serverProviderId
                                    }
                                    data-testid="select-provider"
                                >
                                    <option value="">
                                        {translate("common.choose")}
                                    </option>
                                    {providers?.length &&
                                        providers
                                            .filter(
                                                (item: ServerProviderData) =>
                                                    item.enabled
                                            )
                                            .map((item: ServerProviderData) => (
                                                <option
                                                    key={item.id}
                                                    value={item.id}
                                                >
                                                    {item.name}
                                                </option>
                                            ))}
                                </Form.Control>
                                <Form.Control.Feedback type="invalid">
                                    {errors.serverProviderId}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Row>
                        <Row>
                            <Form.Group
                                className="mb-2"
                                as={Col}
                                md
                                controlId="generalForm.name"
                            >
                                <Form.Label>
                                    {translate("generalForm.serverName")}
                                    {requiredFieldIcon}
                                </Form.Label>
                                <Form.Control
                                    aria-label="name-input"
                                    type="text"
                                    name="name"
                                    maxLength={maxLengthLarge}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.name}
                                    isInvalid={!!touched.name && !!errors.name}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errors.name}
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group
                                className="mb-2"
                                as={Col}
                                md
                                controlId="generalForm.ipAddress"
                            >
                                <Form.Label>
                                    {translate("generalForm.ipAddress")}
                                    {requiredFieldIcon}
                                </Form.Label>
                                <Form.Control
                                    aria-label="ip-address-input"
                                    type="text"
                                    placeholder="127.0.0.1"
                                    name="ipAddress"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.ipAddress}
                                    isInvalid={
                                        !!touched.ipAddress &&
                                        !!errors.ipAddress
                                    }
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errors.ipAddress}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Row>
                        <Row>
                            <FieldArray
                                name="locales"
                                render={(arrayHelpers) => (
                                    <Form.Group
                                        className="mb-2"
                                        as={Col}
                                        md={6}
                                        controlId="generalForm.languagesList"
                                    >
                                        <Form.Label>
                                            {translate(
                                                "generalForm.languagesList"
                                            )}
                                            {requiredFieldIcon}
                                        </Form.Label>
                                        <Form.Control
                                            aria-label="language-select"
                                            as="select"
                                            isInvalid={
                                                (!!touched.locales &&
                                                    !!errors.locales) ||
                                                values.locales.length < 1 ||
                                                values.locales.length >
                                                    maxLocales
                                            }
                                            onChange={(e) => {
                                                arrayHelpers.push(
                                                    e.target.value
                                                );
                                            }}
                                        >
                                            <option
                                                value=""
                                                label={translate(
                                                    "common.choose"
                                                )}
                                            />
                                            {allLocales?.length &&
                                                allLocales
                                                    .filter(
                                                        (item) =>
                                                            !values.locales.includes(
                                                                item
                                                            )
                                                    )
                                                    .map((locale: string) => (
                                                        <option
                                                            key={locale}
                                                            value={locale}
                                                            label={getLanguage(
                                                                locale
                                                            )}
                                                        />
                                                    ))}
                                        </Form.Control>
                                        <Form.Control.Feedback type="invalid">
                                            {errors.locales}
                                        </Form.Control.Feedback>
                                        <div
                                            className="d-flex flex-wrap mt-1"
                                            data-testid="languages-selected"
                                        >
                                            {values.locales.map(
                                                (item, index) => (
                                                    // eslint-disable-next-line react/no-array-index-key
                                                    <div key={index}>
                                                        <Button
                                                            variant="outline-primary"
                                                            className="me-1 mb-1"
                                                            type="button"
                                                            onClick={() =>
                                                                arrayHelpers.remove(
                                                                    index
                                                                )
                                                            }
                                                        >
                                                            {getLanguage(item)}{" "}
                                                            <span className="ms-2 text-danger">
                                                                {crossSmallIcon}
                                                            </span>
                                                        </Button>
                                                    </div>
                                                )
                                            )}
                                        </div>
                                    </Form.Group>
                                )}
                            />
                            <Form.Group
                                className="mb-2"
                                as={Col}
                                md
                                controlId="generalForm.availableAdmins"
                            >
                                <Form.Label>
                                    {translate("generalForm.availableAdmins")}
                                    {requiredFieldIcon}
                                </Form.Label>
                                <Form.Control
                                    aria-label="available-admins-input"
                                    type="number"
                                    min="0"
                                    name="availableAdmins"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.availableAdmins}
                                    isInvalid={
                                        !!touched.availableAdmins &&
                                        !!errors.availableAdmins
                                    }
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errors.availableAdmins}
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group
                                className="mb-2"
                                as={Col}
                                md
                                controlId="generalForm.playerSlots"
                            >
                                <Form.Label>
                                    {translate("generalForm.playerSlots")}
                                    {requiredFieldIcon}
                                </Form.Label>
                                <Form.Control
                                    aria-label="player-slots-input"
                                    type="number"
                                    min="0"
                                    name="playerSlots"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.playerSlots}
                                    isInvalid={
                                        !!touched.playerSlots &&
                                        !!errors.playerSlots
                                    }
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errors.playerSlots}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Row>
                        <Form.Group
                            className="mb-2"
                            controlId="generalForm.additionalComments"
                        >
                            <Form.Label>
                                {translate("generalForm.additionalComments")}
                            </Form.Label>
                            <p>
                                {translate(
                                    "generalForm.additionalCommentsInfo"
                                )}
                            </p>
                            <Form.Control
                                aria-label="additional-comments-input"
                                as="textarea"
                                rows={3}
                                name="additionalComments"
                                maxLength={maxLengthExtraLarge}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.additionalComments}
                                isInvalid={
                                    !!touched.additionalComments &&
                                    !!errors.additionalComments
                                }
                            />
                            <Form.Control.Feedback type="invalid">
                                {errors.additionalComments}
                            </Form.Control.Feedback>
                        </Form.Group>
                        <div className="d-flex">
                            <Button
                                variant="primary"
                                className="ms-auto"
                                role="button"
                                type="submit"
                            >
                                {translate("common.next")} {chevronRightIcon}
                            </Button>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    );
};

export default GeneralForm;
