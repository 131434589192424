import { useEffect } from "react";
import { DateTime } from "luxon";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "../../translations/i18n";
import { Field, FieldArray, FieldMetaProps, Formik } from "formik";
import * as Yup from "yup";
import { httpStatus } from "../../shared/api/api";
import { useAppDispatch, useAppSelector } from "../../shared/hooks";
import {
    maxLengthExtraLarge,
    maxLengthLarge,
    maxLengthMedium,
    maxLengthSmall,
    maxLocales,
    maxPlayerSlots,
    maxSizeNumber,
    minAdmins,
    minPlayerSlots,
    steamIdLength
} from "../../shared/constants/validation";
import {
    selectCommunity,
    selectGeneralInfo,
    selectHardware,
    selectPrimaryContact,
    selectSingle,
    selectLoading as selectServerLoading,
    selectAdminAvailability,
    selectAdditionalContacts
} from "./server.selectors";
import {
    diffContactFormData,
    diffFormData,
    ServerUpdateAvailabilityFormData,
    ServerUpdateContactFormData,
    ServerUpdateFormData,
    ServerUpdateHardwareFormData,
    updateServer
} from "./server.slice";
import {
    ipAddressPattern,
    numbersPattern,
    urlOptionalHttp
} from "../../shared/constants/regex";
import {
    crossSmallIcon,
    minusIcon,
    plusIcon,
    requiredFieldIcon
} from "../../shared/constants/unicodeIcons";
import { selectList as selectProvidersList } from "../serverProviders/serverProvider.selectors";
import {
    ServerProviderData,
    getList as getProvidersList
} from "../serverProviders/serverProvider.slice";
import {
    getDefaultLocale,
    getLanguage,
    getLocalesList
} from "../../shared/utils/languageUtil";
import {
    getDuration,
    getHoursList,
    getStartOfToday,
    getTranslatedTimeZone
} from "../../shared/utils/timeUtils";

const ServerForm: React.FC = () => {
    const { t: translate } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const server = useAppSelector(selectSingle);
    const providers = useAppSelector(selectProvidersList);
    const serverLoading = useAppSelector(selectServerLoading);
    const generalInfo = useAppSelector(selectGeneralInfo);
    const hardware = useAppSelector(selectHardware);
    const community = useAppSelector(selectCommunity);
    const contacts = [
        ...[useAppSelector(selectPrimaryContact)],
        ...useAppSelector(selectAdditionalContacts)
    ];
    const allLocales = [...getLocalesList()];
    const adminAvailability = useAppSelector(selectAdminAvailability);

    useEffect(() => {
        dispatch(getProvidersList());
    }, [dispatch]);

    const maxTimeSlots = 3;
    const timeZone = getTranslatedTimeZone();
    const getHours = () => {
        const list = [
            <option key="..." value="">
                {translate("common.choose")}
            </option>
        ];
        getHoursList().forEach((dateTime) => {
            const time = dateTime.toFormat("T");
            list.push(
                <option key={time} value={time}>
                    {time}
                </option>
            );
        });
        return list;
    };

    const getPrimaryContact = () => contacts?.find((e) => e?.isPrimary);

    const requiresHardwareProfile = (id?: string) =>
        providers
            ?.filter((i: ServerProviderData) => i.id === id)
            .every((i: ServerProviderData) => i.hardwareProfileRequired);

    const hourOptions = getHours();
    const availabilityInitialValues: ServerUpdateAvailabilityFormData = {
        isAlwaysAvailable: adminAvailability.some(
            (e) => e.duration.as("hours") === 24 && e.weekends && e.weekdays
        ),
        times: adminAvailability.map((a) => {
            return {
                startTime: a.startTime.toFormat("T"),
                endTime: a.endTime?.toFormat("T"),
                duration: a.duration.as("hours"),
                weekends: a.weekends,
                weekdays: a.weekdays
            };
        })
    };

    const getDurationHours = (start: string, end: string) => {
        let hours = 0;
        let isOvernight = false;
        if (start && end) {
            const startTime = DateTime.fromFormat(start, "T");
            const endTime = DateTime.fromFormat(end, "T");
            hours = Math.floor(getDuration(startTime, endTime).as("hours"));
            isOvernight = startTime > endTime;
        }
        return (
            <span>
                {isOvernight
                    ? translate("adminAvailabilityForm.durationOvernight", {
                          hours
                      })
                    : translate("adminAvailabilityForm.duration", {
                          hours
                      })}
            </span>
        );
    };

    const defaultAvailability: ServerUpdateAvailabilityFormData = {
        isAlwaysAvailable: false,
        times: [
            {
                startTime: "",
                endTime: "",
                duration: 0,
                weekdays: true,
                weekends: true
            }
        ]
    };

    const initialValues: ServerUpdateFormData = {
        id: server?.id || "",
        userId: getPrimaryContact()?.id,
        serverName: generalInfo?.name,
        serverPlayerSlots: generalInfo?.playerSlots,
        availableAdmins: generalInfo?.availableAdmins,
        serverProviderId: generalInfo?.serverProviderId,
        ipAddress: generalInfo?.ipAddress,
        locales: generalInfo?.locales || [getDefaultLocale()],
        additionalComments: generalInfo?.additionalComments,
        adminAvailability: availabilityInitialValues,
        ...(server?.serverProvider.hardwareProfileRequired
            ? {
                  hardwareProfile: {
                      operatingSystem: hardware?.operatingSystem,
                      cpuModel: hardware?.cpuModel,
                      cpuFrequency: hardware?.cpuFrequency,
                      networkSpeed: hardware?.networkSpeed,
                      diskDrives: hardware?.diskDrives,
                      physicalMemory: hardware?.physicalMemory,
                      location: hardware?.location,
                      hostingCompany: hardware?.hostingCompany
                  }
              }
            : {}),
        contacts: contacts.map((c) => ({
            id: c?.id || "",
            country: c?.country || "",
            name: c?.name || "",
            nameAliasId: c?.nameAliasId || "",
            email: c?.email || "",
            emailAliasId: c?.emailAliasId || "",
            forumName: c?.forumName || "",
            forumAliasId: c?.forumAliasId || "",
            discordName: c?.discordName || "",
            discordAliasId: c?.discordAliasId || "",
            steamId: c?.steamId || "",
            steamAliasId: c?.steamAliasId || ""
        })),
        communityName: community?.name,
        communityUrl: community?.url,
        communityTag: community?.tag,
        communitySize: community?.size
    };

    const adminAvailabilitySchema = Yup.object({
        isAlwaysAvailable: Yup.boolean(),
        times: Yup.mixed().when(["isAlwaysAvailable"], {
            is: (value: boolean) => !value,
            then: Yup.array()
                .of(
                    Yup.object({
                        startTime: Yup.string().required(
                            translate("adminAvailabilityForm.startTimeRequired")
                        ),
                        endTime: Yup.string().required(
                            translate("adminAvailabilityForm.endTimeRequired")
                        ),
                        weekends: Yup.boolean(),
                        weekdays: Yup.boolean()
                    })
                )
                .test("one-true", "required", (valuesList) => {
                    if (!valuesList || !valuesList.length) {
                        return true;
                    }
                    const errors = Array<Yup.ValidationError>();
                    valuesList.forEach((timeSlot, index) => {
                        if (!timeSlot.weekdays && !timeSlot.weekends) {
                            errors.push(
                                new Yup.ValidationError(
                                    translate(
                                        "adminAvailabilityForm.partOfWeekRequired"
                                    ),
                                    null,
                                    `adminAvailability.times.${index}.weekends`
                                )
                            );
                            errors.push(
                                new Yup.ValidationError(
                                    translate(
                                        "adminAvailabilityForm.partOfWeekRequired"
                                    ),
                                    null,
                                    `adminAvailability.times.${index}.weekdays`
                                )
                            );
                        }
                    });
                    return errors.length
                        ? new Yup.ValidationError(errors)
                        : true;
                })
        })
    });

    const generalInfoSchema = Yup.object({
        serverProviderId: Yup.string().required(
            translate("generalForm.serverProviderRequired")
        ),
        serverName: Yup.string().required(
            translate("generalForm.serverNameRequired")
        ),
        availableAdmins: Yup.number()
            .integer(translate("common.integerRequired"))
            .required(translate("generalForm.availableAdminsRequired"))
            .moreThan(minAdmins, translate("generalForm.availableAdminsUnder"))
            .lessThan(
                maxSizeNumber,
                translate("generalForm.availableAdminsOver")
            ),
        ipAddress: Yup.string()
            .required(translate("generalForm.ipAddressRequired"))
            .matches(
                ipAddressPattern,
                translate("generalForm.ipAddressInvalid")
            ),
        serverPlayerSlots: Yup.number()
            .integer(translate("common.integerRequired"))
            .required(translate("generalForm.playerSlotsRequired"))
            .moreThan(
                minPlayerSlots - 1,
                translate("generalForm.playerSlotsUnder")
            )
            .lessThan(
                maxPlayerSlots + 1,
                translate("generalForm.playerSlotsOver")
            ),
        locales: Yup.array()
            .min(1, translate("generalForm.localesUnder"))
            .max(maxLocales, translate("generalForm.localesOver"))
    });

    const communitySchema = Yup.object({
        communityName: Yup.string().required(translate("common.nameRequired")),
        communitySize: Yup.number()
            .integer(translate("common.integerRequired"))
            .moreThan(-1, translate("communityForm.sizeLessThanZero"))
            .lessThan(maxSizeNumber, translate("communityForm.sizeOver")),
        communityUrl: Yup.string()
            .required(translate("communityForm.urlRequired"))
            .matches(urlOptionalHttp, translate("communityForm.urlInvalid"))
    });

    const hardwareProfileSchema = Yup.object({
        operatingSystem: Yup.string().required(
            translate("hardwareForm.operatingSystemRequired")
        ),
        cpuModel: Yup.string().required(
            translate("hardwareForm.cpuModelRequired")
        ),
        cpuFrequency: Yup.string().required(
            translate("hardwareForm.cpuFrequencyRequired")
        ),
        physicalMemory: Yup.string().required(
            translate("hardwareForm.physicalMemoryRequired")
        ),
        diskDrives: Yup.string().required(
            translate("hardwareForm.diskDrivesRequired")
        ),
        networkSpeed: Yup.string().required(
            translate("hardwareForm.networkSpeedRequired")
        ),
        location: Yup.string().required(
            translate("hardwareForm.locationRequired")
        )
    });

    const contactSchema = Yup.array()
        .of(
            Yup.object({
                name: Yup.string().required(
                    translate("contactForm.nameRequired")
                ),
                country: Yup.string().required(
                    translate("contactForm.countryRequired")
                ),
                email: Yup.string()
                    .required(translate("contactForm.emailRequired"))
                    .email(translate("contactForm.emailInvalid")),
                discordName: Yup.string(),
                steamId: Yup.string()
                    .required(translate("contactForm.steamIdRequired"))
                    .length(
                        steamIdLength,
                        translate("contactForm.steamIdLength")
                    )
                    .matches(
                        numbersPattern,
                        translate("contactForm.steamIdLetters")
                    ),
                forumName: Yup.string()
            })
        )
        .min(2, translate("contactForm.bothRequired"));

    const schema = Yup.object({
        hardwareProfile: Yup.mixed().when(["serverProviderId"], {
            is: (value: string) => requiresHardwareProfile(value),
            then: hardwareProfileSchema
        }),
        adminAvailability: adminAvailabilitySchema,
        contacts: contactSchema
    })
        .concat(generalInfoSchema)
        .concat(communitySchema);

    const submitHandler = async (values: ServerUpdateFormData) => {
        const base: ServerUpdateFormData = { id: values.id };

        const changed: ServerUpdateFormData = Object.entries(values).reduce(
            (diff, [key, value]) => {
                let update = {};
                const hasChange =
                    value !== initialValues[key as keyof ServerUpdateFormData];

                if (!hasChange) {
                    return diff;
                }

                if (
                    key === "hardwareProfile" &&
                    initialValues.hardwareProfile
                ) {
                    update = {
                        ...update,
                        ...{
                            [key]: diffFormData(
                                value,
                                initialValues.hardwareProfile
                            )
                        }
                    };
                } else if (key === "contacts") {
                    update = {
                        ...update,
                        [key]: value
                            .map((c: ServerUpdateContactFormData, i: number) =>
                                diffContactFormData(
                                    c,
                                    initialValues.contacts
                                        ? initialValues.contacts[i]
                                        : undefined
                                )
                            )
                            .filter(
                                (e: ServerUpdateContactFormData) =>
                                    Object.keys(e).length > 1
                            )
                    };
                } else {
                    update = { [key]: value };
                }

                return { ...diff, ...update };
            },
            base
        );

        if (changed.adminAvailability?.isAlwaysAvailable) {
            changed.adminAvailability = {
                isAlwaysAvailable: true,
                times: [
                    {
                        startTime: getStartOfToday().toISO().toString(),
                        endTime: getStartOfToday().toISO().toString(),
                        duration: 24,
                        weekends: true,
                        weekdays: true
                    }
                ]
            };
        }

        if (
            values.hardwareProfile &&
            !requiresHardwareProfile(values.serverProviderId)
        ) {
            changed.hardwareProfile = null;
        }

        const resultAction = await dispatch(updateServer(changed));
        if (updateServer.fulfilled.match(resultAction) && server) {
            navigate(`/admin/licenses/${server.id}`, { replace: true });
        }
    };

    const cancelHandler = () => {
        if (server) {
            navigate(`/admin/licenses/${server.id}`, { replace: true });
        }
    };

    const getForm = () => (
        <Formik
            enableReinitialize
            validationSchema={schema}
            initialValues={initialValues}
            onSubmit={submitHandler}
        >
            {({
                handleSubmit,
                handleChange,
                handleBlur,
                setFieldValue,
                values,
                touched,
                isValid,
                errors
            }) => (
                <Form
                    className="card-grid-equal-height"
                    noValidate
                    onSubmit={handleSubmit}
                >
                    <Row>
                        <Col>
                            <Card>
                                <Card.Body>
                                    <Row>
                                        <Card.Title>
                                            {translate("generalForm.title")}
                                        </Card.Title>
                                    </Row>
                                    <Row>
                                        <Form.Group
                                            className="mb-3"
                                            as={Col}
                                            controlId="serverForm.serverName"
                                        >
                                            <Form.Label>
                                                {translate(
                                                    "generalForm.serverName"
                                                )}
                                                {requiredFieldIcon}
                                            </Form.Label>
                                            <Form.Control
                                                aria-label="server-name-input"
                                                type="text"
                                                name="serverName"
                                                maxLength={maxLengthLarge}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.serverName}
                                                isInvalid={
                                                    !!touched.serverName &&
                                                    !!errors.serverName
                                                }
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.serverName}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group
                                            className="mb-3"
                                            as={Col}
                                            md={2}
                                            controlId="serverForm.availableAdmins"
                                        >
                                            <Form.Label>
                                                {translate(
                                                    "generalForm.availableAdmins"
                                                )}
                                                {requiredFieldIcon}
                                            </Form.Label>
                                            <Form.Control
                                                aria-label="available-admins-input"
                                                type="number"
                                                min="0"
                                                name="availableAdmins"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.availableAdmins}
                                                isInvalid={
                                                    !!touched.availableAdmins &&
                                                    !!errors.availableAdmins
                                                }
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.availableAdmins}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group
                                            className="mb-3"
                                            as={Col}
                                            md={2}
                                            controlId="serverForm.playerSlots"
                                        >
                                            <Form.Label>
                                                {translate(
                                                    "generalForm.playerSlots"
                                                )}
                                                {requiredFieldIcon}
                                            </Form.Label>
                                            <Form.Control
                                                aria-label="server-player-slots-input"
                                                type="number"
                                                min="0"
                                                name="serverPlayerSlots"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.serverPlayerSlots}
                                                isInvalid={
                                                    !!touched.serverPlayerSlots &&
                                                    !!errors.serverPlayerSlots
                                                }
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.serverPlayerSlots}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group
                                            className="mb-2"
                                            as={Col}
                                            md={2}
                                            controlId="serverForm.ipAddress"
                                        >
                                            <Form.Label>
                                                {translate(
                                                    "generalForm.ipAddress"
                                                )}
                                                {requiredFieldIcon}
                                            </Form.Label>
                                            <Form.Control
                                                aria-label="ip-address-input"
                                                type="text"
                                                placeholder="127.0.0.1"
                                                name="ipAddress"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.ipAddress}
                                                isInvalid={
                                                    !!touched.ipAddress &&
                                                    !!errors.ipAddress
                                                }
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.ipAddress}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Row>
                                    <Row>
                                        <FieldArray
                                            name="locales"
                                            render={(arrayHelpers) => (
                                                <Form.Group
                                                    className="mb-2"
                                                    as={Col}
                                                    md={6}
                                                    controlId="serverForm.locales"
                                                >
                                                    <Form.Label>
                                                        {translate(
                                                            "generalForm.languagesList"
                                                        )}
                                                        {requiredFieldIcon}
                                                    </Form.Label>
                                                    <Form.Control
                                                        aria-label="language-select"
                                                        as="select"
                                                        isInvalid={
                                                            (!!touched.locales &&
                                                                !!errors.locales) ||
                                                            (values.locales &&
                                                                (values.locales
                                                                    ?.length <
                                                                    1 ||
                                                                    values
                                                                        .locales
                                                                        ?.length >
                                                                        maxLocales))
                                                        }
                                                        onChange={(e) => {
                                                            arrayHelpers.push(
                                                                e.target.value
                                                            );
                                                        }}
                                                    >
                                                        <option
                                                            value=""
                                                            label={translate(
                                                                "common.choose"
                                                            )}
                                                        />
                                                        {allLocales?.length &&
                                                            allLocales
                                                                .filter(
                                                                    (item) =>
                                                                        !values.locales?.includes(
                                                                            item
                                                                        )
                                                                )
                                                                .map(
                                                                    (
                                                                        locale: string
                                                                    ) => (
                                                                        <option
                                                                            key={
                                                                                locale
                                                                            }
                                                                            value={
                                                                                locale
                                                                            }
                                                                            label={getLanguage(
                                                                                locale
                                                                            )}
                                                                        />
                                                                    )
                                                                )}
                                                    </Form.Control>
                                                    <Form.Control.Feedback type="invalid">
                                                        {errors.locales}
                                                    </Form.Control.Feedback>
                                                    <div
                                                        className="d-flex flex-wrap mt-1"
                                                        data-testid="languages-selected"
                                                    >
                                                        {values.locales?.map(
                                                            (item, index) => (
                                                                <div
                                                                    // eslint-disable-next-line react/no-array-index-key
                                                                    key={index}
                                                                >
                                                                    <Button
                                                                        variant="outline-primary"
                                                                        className="me-1 mb-1"
                                                                        type="button"
                                                                        onClick={() =>
                                                                            arrayHelpers.remove(
                                                                                index
                                                                            )
                                                                        }
                                                                    >
                                                                        {getLanguage(
                                                                            item
                                                                        )}{" "}
                                                                        <span className="ms-2 text-danger">
                                                                            {
                                                                                crossSmallIcon
                                                                            }
                                                                        </span>
                                                                    </Button>
                                                                </div>
                                                            )
                                                        )}
                                                    </div>
                                                </Form.Group>
                                            )}
                                        />
                                        <Form.Group
                                            className="mb-2"
                                            as={Col}
                                            md
                                            controlId="serverForm.serverProviderId"
                                        >
                                            <Form.Label>
                                                {translate(
                                                    "generalForm.serverProvider"
                                                )}
                                                {requiredFieldIcon}
                                            </Form.Label>
                                            <Form.Control
                                                aria-label="server-provider-select"
                                                as="select"
                                                name="serverProviderId"
                                                value={values.serverProviderId}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                isInvalid={
                                                    !!touched.serverProviderId &&
                                                    !!errors.serverProviderId
                                                }
                                                data-testid="select-provider"
                                            >
                                                <option value="">
                                                    {translate("common.choose")}
                                                </option>
                                                {providers?.length &&
                                                    providers
                                                        .filter(
                                                            (
                                                                item: ServerProviderData
                                                            ) => item.enabled
                                                        )
                                                        .map(
                                                            (
                                                                item: ServerProviderData
                                                            ) => (
                                                                <option
                                                                    key={
                                                                        item.id
                                                                    }
                                                                    value={
                                                                        item.id
                                                                    }
                                                                >
                                                                    {item.name}
                                                                </option>
                                                            )
                                                        )}
                                            </Form.Control>
                                            <Form.Control.Feedback type="invalid">
                                                {errors.serverProviderId}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Row>
                                    <Row>
                                        <Form.Group
                                            className="mb-2"
                                            controlId="serverForm.additionalComments"
                                        >
                                            <Form.Label>
                                                {translate(
                                                    "generalForm.additionalComments"
                                                )}
                                            </Form.Label>
                                            <Form.Control
                                                aria-label="additional-comments-input"
                                                as="textarea"
                                                rows={3}
                                                name="additionalComments"
                                                maxLength={maxLengthExtraLarge}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={
                                                    values.additionalComments ||
                                                    ""
                                                }
                                                isInvalid={
                                                    !!touched.additionalComments &&
                                                    !!errors.additionalComments
                                                }
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.additionalComments}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Row>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                    {requiresHardwareProfile(values.serverProviderId) && (
                        <Row>
                            <Col>
                                <Card>
                                    <Card.Body>
                                        <Row>
                                            <Card.Title>
                                                {translate(
                                                    "hardwareForm.title"
                                                )}
                                            </Card.Title>
                                        </Row>
                                        <Row>
                                            <Form.Group
                                                className="mb-2"
                                                as={Col}
                                                md
                                                controlId="serverForm.hardwareProfile.operatingSystem"
                                            >
                                                <Form.Label>
                                                    {translate(
                                                        "hardwareForm.operatingSystem"
                                                    )}
                                                    {requiredFieldIcon}
                                                </Form.Label>
                                                <Field name="hardwareProfile.operatingSystem">
                                                    {({
                                                        meta
                                                    }: {
                                                        meta: FieldMetaProps<ServerUpdateHardwareFormData>;
                                                    }) => (
                                                        <>
                                                            <Form.Control
                                                                aria-label="operating-system-input"
                                                                type="text"
                                                                placeholder={translate(
                                                                    "hardwareForm.operatingSystemPlaceholder"
                                                                )}
                                                                name="hardwareProfile.operatingSystem"
                                                                maxLength={
                                                                    maxLengthMedium
                                                                }
                                                                onChange={
                                                                    handleChange
                                                                }
                                                                onBlur={
                                                                    handleBlur
                                                                }
                                                                value={
                                                                    values
                                                                        .hardwareProfile
                                                                        ?.operatingSystem ||
                                                                    ""
                                                                }
                                                                isInvalid={
                                                                    !!meta.touched &&
                                                                    !!meta.error
                                                                }
                                                            />
                                                            <Form.Control.Feedback type="invalid">
                                                                {meta.error}
                                                            </Form.Control.Feedback>
                                                        </>
                                                    )}
                                                </Field>
                                            </Form.Group>

                                            <Form.Group
                                                className="mb-2"
                                                as={Col}
                                                md
                                                controlId="serverForm.hardwareProfile.cpuModel"
                                            >
                                                <Form.Label>
                                                    {translate(
                                                        "hardwareForm.cpuModel"
                                                    )}
                                                    {requiredFieldIcon}
                                                </Form.Label>
                                                <Field name="hardwareProfile.cpuModel">
                                                    {({
                                                        meta
                                                    }: {
                                                        meta: FieldMetaProps<ServerUpdateHardwareFormData>;
                                                    }) => (
                                                        <>
                                                            <Form.Control
                                                                aria-label="cpu-model-input"
                                                                type="text"
                                                                placeholder={translate(
                                                                    "hardwareForm.cpuModelPlaceholder"
                                                                )}
                                                                name="hardwareProfile.cpuModel"
                                                                maxLength={
                                                                    maxLengthMedium
                                                                }
                                                                onChange={
                                                                    handleChange
                                                                }
                                                                onBlur={
                                                                    handleBlur
                                                                }
                                                                value={
                                                                    values
                                                                        .hardwareProfile
                                                                        ?.cpuModel ||
                                                                    ""
                                                                }
                                                                isInvalid={
                                                                    !!meta.touched &&
                                                                    !!meta.error
                                                                }
                                                            />
                                                            <Form.Control.Feedback type="invalid">
                                                                {meta.error}
                                                            </Form.Control.Feedback>
                                                        </>
                                                    )}
                                                </Field>
                                            </Form.Group>
                                            <Form.Group
                                                className="mb-2"
                                                as={Col}
                                                md
                                                controlId="serverForm.hardwareProfile.cpuFrequency"
                                            >
                                                <Form.Label>
                                                    {translate(
                                                        "hardwareForm.cpuFrequency"
                                                    )}
                                                    {requiredFieldIcon}
                                                </Form.Label>
                                                <Field name="hardwareProfile.cpuFrequency">
                                                    {({
                                                        meta
                                                    }: {
                                                        meta: FieldMetaProps<ServerUpdateHardwareFormData>;
                                                    }) => (
                                                        <>
                                                            <Form.Control
                                                                aria-label="cpu-frequency-input"
                                                                type="text"
                                                                placeholder={translate(
                                                                    "hardwareForm.cpuFrequencyPlaceholder"
                                                                )}
                                                                name="hardwareProfile.cpuFrequency"
                                                                maxLength={
                                                                    maxLengthMedium
                                                                }
                                                                onChange={
                                                                    handleChange
                                                                }
                                                                onBlur={
                                                                    handleBlur
                                                                }
                                                                value={
                                                                    values
                                                                        .hardwareProfile
                                                                        ?.cpuFrequency ||
                                                                    ""
                                                                }
                                                                isInvalid={
                                                                    !!meta.touched &&
                                                                    !!meta.error
                                                                }
                                                            />
                                                            <Form.Control.Feedback type="invalid">
                                                                {meta.error}
                                                            </Form.Control.Feedback>
                                                        </>
                                                    )}
                                                </Field>
                                            </Form.Group>
                                        </Row>
                                        <Row>
                                            <Form.Group
                                                className="mb-2"
                                                as={Col}
                                                md
                                                controlId="serverForm.hardwareProfile.physicalMemory"
                                            >
                                                <Form.Label>
                                                    {translate(
                                                        "hardwareForm.physicalMemory"
                                                    )}
                                                    {requiredFieldIcon}
                                                </Form.Label>
                                                <Field name="hardwareProfile.physicalMemory">
                                                    {({
                                                        meta
                                                    }: {
                                                        meta: FieldMetaProps<ServerUpdateHardwareFormData>;
                                                    }) => (
                                                        <>
                                                            <Form.Control
                                                                aria-label="physical-memory-input"
                                                                type="text"
                                                                placeholder={translate(
                                                                    "hardwareForm.physicalMemoryPlaceholder"
                                                                )}
                                                                name="hardwareProfile.physicalMemory"
                                                                maxLength={
                                                                    maxLengthMedium
                                                                }
                                                                onChange={
                                                                    handleChange
                                                                }
                                                                onBlur={
                                                                    handleBlur
                                                                }
                                                                value={
                                                                    values
                                                                        .hardwareProfile
                                                                        ?.physicalMemory ||
                                                                    ""
                                                                }
                                                                isInvalid={
                                                                    !!meta.touched &&
                                                                    !!meta.error
                                                                }
                                                            />
                                                            <Form.Control.Feedback type="invalid">
                                                                {meta.error}
                                                            </Form.Control.Feedback>
                                                        </>
                                                    )}
                                                </Field>
                                            </Form.Group>
                                            <Form.Group
                                                className="mb-2"
                                                as={Col}
                                                md
                                                controlId="serverForm.hardwareProfile.diskDrives"
                                            >
                                                <Form.Label>
                                                    {translate(
                                                        "hardwareForm.diskDrives"
                                                    )}
                                                    {requiredFieldIcon}
                                                </Form.Label>
                                                <Field name="hardwareProfile.diskDrives">
                                                    {({
                                                        meta
                                                    }: {
                                                        meta: FieldMetaProps<ServerUpdateHardwareFormData>;
                                                    }) => (
                                                        <>
                                                            <Form.Control
                                                                aria-label="disk-drives-input"
                                                                type="text"
                                                                placeholder={translate(
                                                                    "hardwareForm.diskDrivesPlaceholder"
                                                                )}
                                                                name="hardwareProfile.diskDrives"
                                                                maxLength={
                                                                    maxLengthMedium
                                                                }
                                                                onChange={
                                                                    handleChange
                                                                }
                                                                onBlur={
                                                                    handleBlur
                                                                }
                                                                value={
                                                                    values
                                                                        .hardwareProfile
                                                                        ?.diskDrives ||
                                                                    ""
                                                                }
                                                                isInvalid={
                                                                    !!meta.touched &&
                                                                    !!meta.error
                                                                }
                                                            />
                                                            <Form.Control.Feedback type="invalid">
                                                                {meta.error}
                                                            </Form.Control.Feedback>
                                                        </>
                                                    )}
                                                </Field>
                                            </Form.Group>
                                            <Form.Group
                                                className="mb-2"
                                                as={Col}
                                                md
                                                controlId="serverForm.hardwareProfile.networkSpeed"
                                            >
                                                <Form.Label>
                                                    {translate(
                                                        "hardwareForm.networkSpeed"
                                                    )}
                                                    {requiredFieldIcon}
                                                </Form.Label>
                                                <Field name="hardwareProfile.networkSpeed">
                                                    {({
                                                        meta
                                                    }: {
                                                        meta: FieldMetaProps<ServerUpdateHardwareFormData>;
                                                    }) => (
                                                        <>
                                                            <Form.Control
                                                                aria-label="network-speed-input"
                                                                type="text"
                                                                placeholder={translate(
                                                                    "hardwareForm.networkSpeedPlaceholder"
                                                                )}
                                                                name="hardwareProfile.networkSpeed"
                                                                maxLength={
                                                                    maxLengthMedium
                                                                }
                                                                onChange={
                                                                    handleChange
                                                                }
                                                                onBlur={
                                                                    handleBlur
                                                                }
                                                                value={
                                                                    values
                                                                        .hardwareProfile
                                                                        ?.networkSpeed ||
                                                                    ""
                                                                }
                                                                isInvalid={
                                                                    !!meta.touched &&
                                                                    !!meta.error
                                                                }
                                                            />
                                                            <Form.Control.Feedback type="invalid">
                                                                {meta.error}
                                                            </Form.Control.Feedback>
                                                        </>
                                                    )}
                                                </Field>
                                            </Form.Group>
                                        </Row>
                                        <Row>
                                            <Form.Group
                                                className="mb-2"
                                                as={Col}
                                                md={8}
                                                controlId="serverForm.hardwareProfile.location"
                                            >
                                                <Form.Label>
                                                    {translate(
                                                        "hardwareForm.location"
                                                    )}
                                                    {requiredFieldIcon}
                                                </Form.Label>
                                                <Field name="hardwareProfile.location">
                                                    {({
                                                        meta
                                                    }: {
                                                        meta: FieldMetaProps<ServerUpdateHardwareFormData>;
                                                    }) => (
                                                        <>
                                                            <Form.Control
                                                                aria-label="location-input"
                                                                type="text"
                                                                placeholder={translate(
                                                                    "hardwareForm.locationPlaceholder"
                                                                )}
                                                                name="hardwareProfile.location"
                                                                maxLength={
                                                                    maxLengthMedium
                                                                }
                                                                onChange={
                                                                    handleChange
                                                                }
                                                                onBlur={
                                                                    handleBlur
                                                                }
                                                                value={
                                                                    values
                                                                        .hardwareProfile
                                                                        ?.location ||
                                                                    ""
                                                                }
                                                                isInvalid={
                                                                    !!meta.touched &&
                                                                    !!meta.error
                                                                }
                                                            />
                                                            <Form.Control.Feedback type="invalid">
                                                                {meta.error}
                                                            </Form.Control.Feedback>
                                                        </>
                                                    )}
                                                </Field>
                                            </Form.Group>
                                            <Form.Group
                                                className="mb-2"
                                                as={Col}
                                                md={4}
                                                controlId="serverForm.hardwareProfile.hostingCompany"
                                            >
                                                <Form.Label>
                                                    {translate(
                                                        "hardwareForm.hostingCompany"
                                                    )}
                                                </Form.Label>
                                                <Field name="hardwareProfile.hostingCompany">
                                                    {({
                                                        meta
                                                    }: {
                                                        meta: FieldMetaProps<ServerUpdateHardwareFormData>;
                                                    }) => (
                                                        <>
                                                            <Form.Control
                                                                aria-label="server-hosting-company-input"
                                                                type="text"
                                                                name="hardwareProfile.hostingCompany"
                                                                maxLength={
                                                                    maxLengthMedium
                                                                }
                                                                onChange={
                                                                    handleChange
                                                                }
                                                                onBlur={
                                                                    handleBlur
                                                                }
                                                                value={
                                                                    values
                                                                        .hardwareProfile
                                                                        ?.hostingCompany ||
                                                                    ""
                                                                }
                                                                isInvalid={
                                                                    !!meta.touched &&
                                                                    !!meta.error
                                                                }
                                                            />
                                                            <Form.Control.Feedback type="invalid">
                                                                {meta.error}
                                                            </Form.Control.Feedback>
                                                        </>
                                                    )}
                                                </Field>
                                            </Form.Group>
                                        </Row>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    )}
                    <Row>
                        <FieldArray
                            name="contacts"
                            render={() =>
                                values.contacts?.map((item, index) => (
                                    // eslint-disable-next-line react/no-array-index-key
                                    <Col key={index}>
                                        <Card>
                                            <Card.Body>
                                                <Row>
                                                    <Card.Title>
                                                        {getPrimaryContact()
                                                            ?.id === item.id
                                                            ? translate(
                                                                  "contactForm.primary"
                                                              )
                                                            : translate(
                                                                  "contactForm.secondary"
                                                              )}
                                                    </Card.Title>
                                                </Row>
                                                <Row>
                                                    <Form.Group
                                                        className="mb-2"
                                                        as={Col}
                                                        md={6}
                                                        controlId={`serverForm.contacts.name.${index}`}
                                                    >
                                                        <Form.Label>
                                                            {translate(
                                                                "contactForm.name"
                                                            )}
                                                            {requiredFieldIcon}
                                                        </Form.Label>
                                                        <Field
                                                            name={`contacts.${index}.name`}
                                                        >
                                                            {({
                                                                meta
                                                            }: {
                                                                meta: FieldMetaProps<ServerUpdateContactFormData>;
                                                            }) => (
                                                                <>
                                                                    <Form.Control
                                                                        aria-label={`server-contact-name-input-${
                                                                            index +
                                                                            1
                                                                        }`}
                                                                        type="text"
                                                                        name={`contacts.${index}.name`}
                                                                        data-testid={`contacts.${index}.name`}
                                                                        maxLength={
                                                                            maxLengthMedium
                                                                        }
                                                                        onChange={
                                                                            handleChange
                                                                        }
                                                                        onBlur={
                                                                            handleBlur
                                                                        }
                                                                        value={
                                                                            item.name
                                                                        }
                                                                        isInvalid={
                                                                            !!meta.touched &&
                                                                            !!meta.error
                                                                        }
                                                                    />
                                                                    <Form.Control.Feedback type="invalid">
                                                                        {
                                                                            meta.error
                                                                        }
                                                                    </Form.Control.Feedback>
                                                                </>
                                                            )}
                                                        </Field>
                                                    </Form.Group>
                                                    <Form.Group
                                                        className="mb-2"
                                                        as={Col}
                                                        md={6}
                                                        controlId={`serverForm.contacts.email.${index}`}
                                                    >
                                                        <Form.Label>
                                                            {translate(
                                                                "contactForm.email"
                                                            )}
                                                            {requiredFieldIcon}
                                                        </Form.Label>
                                                        <Field
                                                            name={`contacts.${index}.email`}
                                                        >
                                                            {({
                                                                meta
                                                            }: {
                                                                meta: FieldMetaProps<ServerUpdateContactFormData>;
                                                            }) => (
                                                                <>
                                                                    <Form.Control
                                                                        aria-label={`server-contact-email-input-${index}`}
                                                                        type="text"
                                                                        name={`contacts.${index}.email`}
                                                                        data-testid={`contacts.${index}.email`}
                                                                        maxLength={
                                                                            maxLengthLarge
                                                                        }
                                                                        onChange={
                                                                            handleChange
                                                                        }
                                                                        onBlur={
                                                                            handleBlur
                                                                        }
                                                                        value={
                                                                            item.email
                                                                        }
                                                                        isInvalid={
                                                                            !!meta.touched &&
                                                                            !!meta.error
                                                                        }
                                                                    />
                                                                    <Form.Control.Feedback type="invalid">
                                                                        {
                                                                            meta.error
                                                                        }
                                                                    </Form.Control.Feedback>
                                                                </>
                                                            )}
                                                        </Field>
                                                    </Form.Group>
                                                </Row>
                                                <Row>
                                                    <Form.Group
                                                        className="mb-2"
                                                        as={Col}
                                                        md={6}
                                                        controlId={`serverForm.contacts.steamId.${index}`}
                                                    >
                                                        <Form.Label>
                                                            {translate(
                                                                "contactForm.steamId"
                                                            )}
                                                            {requiredFieldIcon}
                                                        </Form.Label>
                                                        <Field
                                                            name={`contacts.${index}.steamId`}
                                                        >
                                                            {({
                                                                meta
                                                            }: {
                                                                meta: FieldMetaProps<ServerUpdateContactFormData>;
                                                            }) => (
                                                                <>
                                                                    <Form.Control
                                                                        aria-label={`server-steam-id-input-${index}`}
                                                                        type="text"
                                                                        name={`contacts.${index}.steamId`}
                                                                        data-testid={`contacts.${index}.steamId`}
                                                                        maxLength={
                                                                            maxLengthLarge
                                                                        }
                                                                        onChange={
                                                                            handleChange
                                                                        }
                                                                        onBlur={
                                                                            handleBlur
                                                                        }
                                                                        value={
                                                                            item.steamId
                                                                        }
                                                                        isInvalid={
                                                                            !!meta.touched &&
                                                                            !!meta.error
                                                                        }
                                                                    />
                                                                    <Form.Control.Feedback type="invalid">
                                                                        {
                                                                            meta.error
                                                                        }
                                                                    </Form.Control.Feedback>
                                                                </>
                                                            )}
                                                        </Field>
                                                    </Form.Group>
                                                    <Form.Group
                                                        className="mb-2"
                                                        as={Col}
                                                        md={6}
                                                        controlId={`serverForm.contacts.country.${index}`}
                                                    >
                                                        <Form.Label>
                                                            {translate(
                                                                "contactForm.country"
                                                            )}
                                                            {requiredFieldIcon}
                                                        </Form.Label>
                                                        <Field
                                                            name={`contacts.${index}.country`}
                                                        >
                                                            {({
                                                                meta
                                                            }: {
                                                                meta: FieldMetaProps<ServerUpdateContactFormData>;
                                                            }) => (
                                                                <>
                                                                    <Form.Control
                                                                        aria-label={`contact-country-input-${index}`}
                                                                        type="text"
                                                                        name={`contacts.${index}.country`}
                                                                        data-testid={`contacts.${index}.country`}
                                                                        maxLength={
                                                                            maxLengthMedium
                                                                        }
                                                                        onChange={
                                                                            handleChange
                                                                        }
                                                                        onBlur={
                                                                            handleBlur
                                                                        }
                                                                        value={
                                                                            item.country
                                                                        }
                                                                        isInvalid={
                                                                            !!meta.touched &&
                                                                            !!meta.error
                                                                        }
                                                                    />
                                                                    <Form.Control.Feedback type="invalid">
                                                                        {
                                                                            meta.error
                                                                        }
                                                                    </Form.Control.Feedback>
                                                                </>
                                                            )}
                                                        </Field>
                                                    </Form.Group>
                                                </Row>
                                                <Row>
                                                    <Form.Group
                                                        className="mb-2"
                                                        as={Col}
                                                        md={6}
                                                        controlId={`serverForm.contacts.discordName.${index}`}
                                                    >
                                                        <Form.Label>
                                                            {translate(
                                                                "contactForm.discord"
                                                            )}
                                                        </Form.Label>
                                                        <Field
                                                            name={`contacts.${index}.discordName`}
                                                        >
                                                            {({
                                                                meta
                                                            }: {
                                                                meta: FieldMetaProps<ServerUpdateContactFormData>;
                                                            }) => (
                                                                <>
                                                                    <Form.Control
                                                                        aria-label={`server-discord-name-input-${index}`}
                                                                        type="text"
                                                                        name={`contacts.${index}.discordName`}
                                                                        data-testid={`contacts.${index}.discordName`}
                                                                        maxLength={
                                                                            maxLengthLarge
                                                                        }
                                                                        onChange={
                                                                            handleChange
                                                                        }
                                                                        onBlur={
                                                                            handleBlur
                                                                        }
                                                                        value={
                                                                            item.discordName ||
                                                                            ""
                                                                        }
                                                                        isInvalid={
                                                                            !!meta.touched &&
                                                                            !!meta.error
                                                                        }
                                                                    />
                                                                    <Form.Control.Feedback type="invalid">
                                                                        {
                                                                            meta.error
                                                                        }
                                                                    </Form.Control.Feedback>
                                                                </>
                                                            )}
                                                        </Field>
                                                    </Form.Group>
                                                    {item.forumAliasId && (
                                                        <Form.Group
                                                            className="mb-2"
                                                            as={Col}
                                                            md={6}
                                                            controlId={`serverForm.contacts.forumName.${index}`}
                                                        >
                                                            <Form.Label>
                                                                {translate(
                                                                    "contactForm.forum"
                                                                )}
                                                            </Form.Label>
                                                            <Field
                                                                name={`contacts.${index}.forumName`}
                                                            >
                                                                {({
                                                                    meta
                                                                }: {
                                                                    meta: FieldMetaProps<ServerUpdateContactFormData>;
                                                                }) => (
                                                                    <>
                                                                        <Form.Control
                                                                            aria-label={`server-forum-name-input-${index}`}
                                                                            type="text"
                                                                            name={`contacts.${index}.forumName`}
                                                                            data-testid={`contacts.${index}.forumName`}
                                                                            maxLength={
                                                                                maxLengthMedium
                                                                            }
                                                                            onChange={
                                                                                handleChange
                                                                            }
                                                                            onBlur={
                                                                                handleBlur
                                                                            }
                                                                            value={
                                                                                item.forumName ||
                                                                                ""
                                                                            }
                                                                            isInvalid={
                                                                                !!meta.touched &&
                                                                                !!meta.error
                                                                            }
                                                                        />
                                                                        <Form.Control.Feedback type="invalid">
                                                                            {
                                                                                meta.error
                                                                            }
                                                                        </Form.Control.Feedback>
                                                                    </>
                                                                )}
                                                            </Field>
                                                        </Form.Group>
                                                    )}
                                                </Row>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                ))
                            }
                        />
                    </Row>
                    <Row>
                        <Col>
                            <Card>
                                <Card.Body>
                                    <Row>
                                        <Card.Title>
                                            {translate("communityForm.title")}
                                        </Card.Title>
                                    </Row>
                                    <Row>
                                        <Form.Group
                                            className="mb-2"
                                            as={Col}
                                            md={6}
                                            controlId="serverForm.communityName"
                                        >
                                            <Form.Label>
                                                {translate(
                                                    "communityForm.name"
                                                )}
                                                {requiredFieldIcon}
                                            </Form.Label>
                                            <Form.Control
                                                aria-label="community-name-input"
                                                type="text"
                                                name="communityName"
                                                maxLength={maxLengthMedium}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.communityName}
                                                isInvalid={
                                                    !!touched.communityName &&
                                                    !!errors.communityName
                                                }
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.communityName}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group
                                            className="mb-2"
                                            as={Col}
                                            md={3}
                                            controlId="serverForm.communityTag"
                                        >
                                            <Form.Label>
                                                {translate("communityForm.tag")}
                                            </Form.Label>
                                            <Form.Control
                                                aria-label="community-tag-input"
                                                type="string"
                                                name="communityTag"
                                                maxLength={maxLengthSmall}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={
                                                    values.communityTag || ""
                                                }
                                                isInvalid={
                                                    !!touched.communityTag &&
                                                    !!errors.communityTag
                                                }
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.communityTag}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group
                                            className="mb-2"
                                            as={Col}
                                            md={3}
                                            controlId="serverForm.communitySize"
                                        >
                                            <Form.Label>
                                                {translate(
                                                    "communityForm.size"
                                                )}
                                            </Form.Label>
                                            <Form.Control
                                                aria-label="community-size-input"
                                                type="number"
                                                name="communitySize"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={
                                                    values.communitySize || ""
                                                }
                                                isInvalid={
                                                    !!touched.communitySize &&
                                                    !!errors.communitySize
                                                }
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.communitySize}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Row>
                                    <Row>
                                        <Form.Group
                                            className="mb-2"
                                            as={Col}
                                            controlId="serverForm.communityUrl"
                                        >
                                            <Form.Label>
                                                {translate("communityForm.url")}
                                                {requiredFieldIcon}
                                            </Form.Label>
                                            <Form.Control
                                                aria-label="community-url-input"
                                                type="text"
                                                name="communityUrl"
                                                maxLength={maxLengthLarge}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.communityUrl}
                                                isInvalid={
                                                    !!touched.communityUrl &&
                                                    !!errors.communityUrl
                                                }
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.communityUrl}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Row>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Card>
                                <Card.Body>
                                    <Row>
                                        <Card.Title>
                                            {translate(
                                                "adminAvailabilityForm.title"
                                            )}
                                        </Card.Title>
                                    </Row>
                                    <Row>
                                        <Form.Group
                                            className="mb-2"
                                            as={Col}
                                            controlId="serverForm.adminAvailability.isAlwaysAvailable"
                                            md={6}
                                        >
                                            <Form.Check
                                                label={translate(
                                                    "adminAvailabilityForm.isAlwaysAvailable"
                                                )}
                                                name="adminAvailability.isAlwaysAvailable"
                                                type="checkbox"
                                                id="isAlwaysAvailable"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                defaultChecked={
                                                    values.adminAvailability
                                                        ?.isAlwaysAvailable
                                                }
                                            />
                                        </Form.Group>
                                    </Row>
                                    <Row>
                                        <FieldArray
                                            name="adminAvailability.times"
                                            render={(arrayHelpers) => (
                                                <div>
                                                    {values.adminAvailability?.times.map(
                                                        (item, index) => (
                                                            <div
                                                                className="mb-2"
                                                                // eslint-disable-next-line react/no-array-index-key
                                                                key={index}
                                                            >
                                                                <Row className="mb-2 d-flex pb-1">
                                                                    <Form.Group
                                                                        as={Col}
                                                                        lg
                                                                        controlId={`serverForm.adminAvailability.startTime.${index}`}
                                                                    >
                                                                        <Form.Label>
                                                                            {translate(
                                                                                "adminAvailabilityForm.startTime"
                                                                            )}{" "}
                                                                            {timeZone &&
                                                                                translate(
                                                                                    "common.parentheses",
                                                                                    {
                                                                                        value: timeZone
                                                                                    }
                                                                                )}
                                                                        </Form.Label>
                                                                        <Field
                                                                            name={`adminAvailability.times.${index}.startTime`}
                                                                        >
                                                                            {({
                                                                                meta
                                                                            }: {
                                                                                meta: FieldMetaProps<ServerUpdateAvailabilityFormData>;
                                                                            }) => (
                                                                                <>
                                                                                    <Form.Control
                                                                                        aria-label={`admin-${
                                                                                            index +
                                                                                            1
                                                                                        }-start-time`}
                                                                                        as="select"
                                                                                        name={`adminAvailability.times.${index}.startTime`}
                                                                                        data-testid={`adminAvailability.times.${index}.startTime`}
                                                                                        onChange={
                                                                                            handleChange
                                                                                        }
                                                                                        onBlur={
                                                                                            handleBlur
                                                                                        }
                                                                                        value={
                                                                                            item.startTime
                                                                                        }
                                                                                        isInvalid={
                                                                                            !!meta.touched &&
                                                                                            !!meta.error
                                                                                        }
                                                                                        disabled={
                                                                                            values
                                                                                                .adminAvailability
                                                                                                ?.isAlwaysAvailable
                                                                                        }
                                                                                    >
                                                                                        {
                                                                                            hourOptions
                                                                                        }
                                                                                    </Form.Control>
                                                                                    <Form.Control.Feedback type="invalid">
                                                                                        {
                                                                                            meta.error
                                                                                        }
                                                                                    </Form.Control.Feedback>
                                                                                </>
                                                                            )}
                                                                        </Field>
                                                                    </Form.Group>
                                                                    <Form.Group
                                                                        as={Col}
                                                                        lg
                                                                        controlId={`serverForm.adminAvailability.endTime.${index}`}
                                                                    >
                                                                        <Form.Label>
                                                                            {translate(
                                                                                "adminAvailabilityForm.endTime"
                                                                            )}{" "}
                                                                            {getDurationHours(
                                                                                values
                                                                                    ?.adminAvailability
                                                                                    ?.times[
                                                                                    index
                                                                                ]
                                                                                    ?.startTime ||
                                                                                    "",
                                                                                values
                                                                                    ?.adminAvailability
                                                                                    ?.times[
                                                                                    index
                                                                                ]
                                                                                    ?.endTime ||
                                                                                    ""
                                                                            )}
                                                                        </Form.Label>
                                                                        <Field
                                                                            name={`adminAvailability.times.${index}.endTime`}
                                                                        >
                                                                            {({
                                                                                meta
                                                                            }: {
                                                                                meta: FieldMetaProps<ServerUpdateAvailabilityFormData>;
                                                                            }) => (
                                                                                <>
                                                                                    <Form.Control
                                                                                        aria-label={`admin-${
                                                                                            index +
                                                                                            1
                                                                                        }-end-time`}
                                                                                        as="select"
                                                                                        name={`adminAvailability.times.${index}.endTime`}
                                                                                        data-testid={`adminAvailability.times.${index}.endTime`}
                                                                                        onChange={
                                                                                            handleChange
                                                                                        }
                                                                                        onBlur={
                                                                                            handleBlur
                                                                                        }
                                                                                        value={
                                                                                            item.endTime
                                                                                        }
                                                                                        isInvalid={
                                                                                            !!meta.touched &&
                                                                                            !!meta.error
                                                                                        }
                                                                                        disabled={
                                                                                            values
                                                                                                .adminAvailability
                                                                                                ?.isAlwaysAvailable
                                                                                        }
                                                                                    >
                                                                                        {getHours()}
                                                                                    </Form.Control>
                                                                                    <Form.Control.Feedback type="invalid">
                                                                                        {
                                                                                            meta.error
                                                                                        }
                                                                                    </Form.Control.Feedback>
                                                                                </>
                                                                            )}
                                                                        </Field>
                                                                    </Form.Group>
                                                                    <Form.Group
                                                                        as={Col}
                                                                        md={2}
                                                                        className="mb-0 mt-4"
                                                                        controlId="serverForm.adminAvailability.partOfWeek"
                                                                    >
                                                                        <Field
                                                                            name={`adminAvailability.times.${index}.weekdays`}
                                                                        >
                                                                            {({
                                                                                meta
                                                                            }: {
                                                                                meta: FieldMetaProps<ServerUpdateAvailabilityFormData>;
                                                                            }) => (
                                                                                <Form.Check
                                                                                    aria-label={`admin-${
                                                                                        index +
                                                                                        1
                                                                                    }-weekdays`}
                                                                                    type="switch"
                                                                                    role="switch"
                                                                                    label={translate(
                                                                                        "adminAvailabilityForm.weekdays"
                                                                                    )}
                                                                                    id={`adminAvailability.times.${index}.weekdays`}
                                                                                    data-testid={`adminAvailability.times.${index}.weekdays`}
                                                                                    onChange={(
                                                                                        event
                                                                                    ) =>
                                                                                        setFieldValue(
                                                                                            `adminAvailability.times.${index}.weekdays`,
                                                                                            event
                                                                                                .target
                                                                                                .checked
                                                                                        )
                                                                                    }
                                                                                    onBlur={
                                                                                        handleBlur
                                                                                    }
                                                                                    checked={
                                                                                        item.weekdays
                                                                                    }
                                                                                    isInvalid={
                                                                                        !!meta.error
                                                                                    }
                                                                                    disabled={
                                                                                        values
                                                                                            .adminAvailability
                                                                                            ?.isAlwaysAvailable
                                                                                    }
                                                                                />
                                                                            )}
                                                                        </Field>
                                                                        <Field
                                                                            name={`adminAvailability.times.${index}.weekends`}
                                                                        >
                                                                            {({
                                                                                meta
                                                                            }: {
                                                                                // eslint-disable-next-line react/no-unused-prop-types
                                                                                meta: FieldMetaProps<ServerUpdateAvailabilityFormData>;
                                                                            }) => (
                                                                                <>
                                                                                    <Form.Check
                                                                                        aria-label={`admin-${
                                                                                            index +
                                                                                            1
                                                                                        }-weekends`}
                                                                                        type="switch"
                                                                                        role="switch"
                                                                                        label={translate(
                                                                                            "adminAvailabilityForm.weekends"
                                                                                        )}
                                                                                        id={`adminAvailability.times.${index}.weekends`}
                                                                                        data-testid={`adminAvailability.times.${index}.weekends`}
                                                                                        onChange={(
                                                                                            event
                                                                                        ) =>
                                                                                            setFieldValue(
                                                                                                `adminAvailability.times.${index}.weekends`,
                                                                                                event
                                                                                                    .target
                                                                                                    .checked
                                                                                            )
                                                                                        }
                                                                                        onBlur={
                                                                                            handleBlur
                                                                                        }
                                                                                        checked={
                                                                                            item.weekends
                                                                                        }
                                                                                        isInvalid={
                                                                                            !!meta.error
                                                                                        }
                                                                                        disabled={
                                                                                            values
                                                                                                .adminAvailability
                                                                                                ?.isAlwaysAvailable
                                                                                        }
                                                                                    />
                                                                                    <Form.Control.Feedback
                                                                                        type="invalid"
                                                                                        className={
                                                                                            meta.error
                                                                                                ? "d-block"
                                                                                                : ""
                                                                                        }
                                                                                    >
                                                                                        {
                                                                                            meta.error
                                                                                        }
                                                                                    </Form.Control.Feedback>
                                                                                </>
                                                                            )}
                                                                        </Field>
                                                                    </Form.Group>
                                                                    <Form.Group
                                                                        as={Col}
                                                                        md={2}
                                                                        className="mb-0 mt-4"
                                                                    >
                                                                        {values
                                                                            .adminAvailability
                                                                            ?.times &&
                                                                            values
                                                                                .adminAvailability
                                                                                ?.times
                                                                                .length >
                                                                                1 && (
                                                                                <Button
                                                                                    className="align-self-end"
                                                                                    variant="danger"
                                                                                    type="button"
                                                                                    onClick={() =>
                                                                                        arrayHelpers.remove(
                                                                                            index
                                                                                        )
                                                                                    }
                                                                                    disabled={
                                                                                        values
                                                                                            .adminAvailability
                                                                                            ?.isAlwaysAvailable
                                                                                    }
                                                                                >
                                                                                    {
                                                                                        minusIcon
                                                                                    }{" "}
                                                                                    {translate(
                                                                                        "adminAvailabilityForm.remove"
                                                                                    )}
                                                                                </Button>
                                                                            )}
                                                                    </Form.Group>
                                                                </Row>
                                                            </div>
                                                        )
                                                    )}
                                                    {values.adminAvailability
                                                        ?.times &&
                                                        values.adminAvailability
                                                            .times.length <
                                                            maxTimeSlots && (
                                                            <Button
                                                                variant="success"
                                                                type="button"
                                                                onClick={() =>
                                                                    arrayHelpers.push(
                                                                        defaultAvailability
                                                                            .times[0]
                                                                    )
                                                                }
                                                                disabled={
                                                                    values
                                                                        .adminAvailability
                                                                        ?.isAlwaysAvailable
                                                                }
                                                            >
                                                                {plusIcon}{" "}
                                                                {translate(
                                                                    "adminAvailabilityForm.add"
                                                                )}
                                                            </Button>
                                                        )}
                                                </div>
                                            )}
                                        />
                                    </Row>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                    <div className="w-100 d-flex justify-content-end">
                        <Button
                            variant="secondary"
                            role="button"
                            className="me-2"
                            onClick={cancelHandler}
                        >
                            {translate("common.cancel")}
                        </Button>
                        <Button
                            variant="primary"
                            role="button"
                            type="submit"
                            disabled={
                                serverLoading === httpStatus.pending || !isValid
                            }
                        >
                            {translate("common.save")}
                        </Button>
                    </div>
                </Form>
            )}
        </Formik>
    );

    return <div>{server && getForm()}</div>;
};

export default ServerForm;
