import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { DateTime } from "luxon";
import { getDuration } from "../../shared/utils/timeUtils";
import { api, httpStatus } from "../../shared/api/api";
import endpoints from "../../shared/api/endpoints";
import AppError from "../../shared/errors/appError";
import { GameData } from "../games/game.slice";
import { ServerProviderData } from "../serverProviders/serverProvider.slice";
import { DateTimeAvailability } from "../licenseApplication/licenseApplication.selectors";
import radix from "../../shared/constants/radix";
import { licenseStatus } from "../licenses/license.slice";

const namespace = "server";

export const aliasType = {
    discord: "DISCORD",
    email: "EMAIL",
    forum: "FORUMS",
    name: "NAME",
    steam: "STEAM"
} as const;

export const sortProperty = {
    licenseNumber: "licenseNumber",
    name: "name",
    communityName: "communityName",
    contactEmail: "contactEmail",
    contactName: "contactName",
    game: "game",
    status: "status"
} as const;

export interface LicenseCardData {
    id: string;
    lastModifiedBy: string;
    lastModifiedTimestamp: number;
    licenseNumber: number;
    publicKey?: string | null;
    serverName: string;
    status: string;
    statusReason: string;
}

export interface GeneralData {
    gameId: string;
    gameName: string;
    acceptedTerms: boolean;
    serverProviderId: string;
    serverProviderName: string;
    hardwareProfileRequired: boolean;
    name: string;
    ipAddress: string;
    locales: string[];
    playerSlots: number;
    availableAdmins: number;
    additionalComments?: string;
}

export interface HardwareData {
    operatingSystem: string;
    cpuModel: string;
    cpuFrequency: string;
    physicalMemory: string;
    diskDrives: string;
    networkSpeed: string;
    location: string;
    hostingCompany?: string;
}

export interface CommunityData {
    id?: string;
    name: string;
    size?: number;
    tag?: string;
    url: string;
}

export interface AdminAvailabilityData {
    startTime: string;
    endTime?: string;
    duration: number;
    weekdays: boolean;
    weekends: boolean;
}

export interface ContactData {
    id?: string;
    country: string;
    discordName?: string;
    discordAliasId?: string;
    email: string;
    emailAliasId?: string;
    forumName?: string;
    forumAliasId?: string;
    name: string;
    nameAliasId?: string;
    steamId: string;
    steamAliasId?: string;
    isPrimary?: boolean;
}

export interface AliasData {
    alias: string;
    aliasType: string;
    id: string;
}

export interface Contact {
    aliases: AliasData[];
    country: string;
    id: string;
    primaryContact: boolean;
}

export interface LicenseData {
    contacts: Contact[];
    game: GameData;
    id: string;
    lastModifiedBy: string;
    lastModifiedTimestamp: number;
    licenseNumber: number;
    publicKey?: string | null;
    status: string;
    statusReason: string;
}

export interface ServerData {
    additionalComments: string;
    adminAvailability: AdminAvailabilityData[];
    availableAdmins: number;
    community: CommunityData;
    hardwareProfile?: HardwareData;
    id: string;
    ipAddress: string;
    license: LicenseData;
    locales: string[];
    name: string;
    playerSlots: number;
    serverProvider: ServerProviderData;
}

export interface License {
    id: string;
    licenseNumber: number;
    status: string;
    game: GameData;
    contacts: Contact[];
}

export interface SearchData {
    ipAddress: string;
    community: CommunityData;
    license: License;
    name: string;
    id: string;
}

export interface SearchQuery {
    ipAddress?: string;
    contactEmail?: string;
    licenseNumbers?: string;
    licenseStatuses?: string[];
    contactName?: string;
    communityName?: string;
    contactSteamId?: string;
    gameIds?: string[];
    limit?: number;
    offset?: number;
}

export interface SearchQueryBody {
    ipAddress?: string;
    contactEmail?: string;
    licenseNumbers?: string[];
    licenseStatuses?: string[];
    contactName?: string;
    communityName?: string;
    contactSteamId?: string;
    limit?: number;
    offset?: number;
}

export interface CompressedSearchData {
    [key: string]: string | number;
    communityName: string;
    contactEmail: string;
    contactName: string;
    game: string;
    id: string;
    licenseId: string;
    licenseNumber: number;
    ipAddress: string;
    name: string;
    status: string;
    steamId: string;
}

export interface Server {
    single: ServerData | null;
    license: LicenseCardData | null;
    generalInfo: GeneralData | null;
    hardwareProfile: HardwareData | null;
    community: CommunityData | null;
    adminAvailability: AdminAvailabilityData[];
    primaryContact: ContactData | null;
    additionalContacts: ContactData[];
    list: CompressedSearchData[];
    sort: LicenseSort;
    searchQuery: SearchQuery | null;
    loading: string;
    error: AppError | null;
}

export interface ServerUpdateAvailabilityFormData {
    isAlwaysAvailable: boolean;
    times: AdminAvailabilityData[];
}

export interface ServerUpdateContactFormData {
    id: string;
    country?: string;
    email?: string;
    emailAliasId?: string;
    name?: string;
    nameAliasId?: string;
    forumName?: string | null;
    forumAliasId?: string;
    discordName?: string | null;
    discordAliasId?: string;
    steamId?: string;
    steamAliasId?: string;
}

export interface ServerUpdateHardwareFormData {
    cpuModel?: string;
    cpuFrequency?: string;
    operatingSystem?: string;
    physicalMemory?: string;
    diskDrives?: string;
    networkSpeed?: string;
    location?: string;
    hostingCompany?: string | null;
}

export interface ServerUpdateFormData {
    id: string;
    userId?: string;
    serverName?: string;
    serverPlayerSlots?: number;
    availableAdmins?: number;
    ipAddress?: string;
    serverProviderId?: string;
    locales?: string[];
    additionalComments?: string | null;
    adminAvailability?: ServerUpdateAvailabilityFormData;
    communityUrl?: string;
    communityName?: string;
    communityTag?: string | null;
    communitySize?: number | null;
    hardwareProfile?: ServerUpdateHardwareFormData | null;
    contacts?: ServerUpdateContactFormData[];
}

export const initialState: Server = {
    single: null,
    license: null,
    generalInfo: null,
    hardwareProfile: null,
    community: null,
    adminAvailability: [],
    primaryContact: null,
    additionalContacts: [],
    list: [],
    sort: {
        property: sortProperty.licenseNumber,
        descending: false
    },
    searchQuery: { licenseStatuses: [licenseStatus.applied] },
    loading: httpStatus.idle,
    error: null
};

export interface LicenseSort {
    property: string;
    descending: boolean;
}

export const getServer = createAsyncThunk<
    ServerData,
    string,
    { rejectValue: AppError }
>(`${namespace}/getServer`, async (id, { rejectWithValue }) => {
    try {
        return await api.get<ServerData>(endpoints.server.get(id));
    } catch (error) {
        return rejectWithValue(error as AppError);
    }
});

export interface ServerUpdateBodyCommunityData {
    name?: string;
    tag?: string;
    size?: number;
    url?: string;
}

export interface ServerUpdateBodyAvailabilityData {
    startTime: string;
    duration: number;
    weekends: boolean;
    weekdays: boolean;
}

export interface ServerUpdateBodyHardwareData {
    operatingSystem?: string;
    cpuModel?: string;
    cpuFrequency?: string;
    diskDrives?: string;
    networkSpeed?: string;
    physicalMemory?: string;
    location?: string;
    hostingCompany?: string | null;
}

export interface ServerUpdateBodyData {
    serverPlayerSlots?: number;
    availableAdmins?: number;
    serverName?: string;
    serverProviderId?: string;
    ipAddress?: string;
    locales?: string[];
    community?: ServerUpdateBodyCommunityData;
    adminAvailability?: ServerUpdateBodyAvailabilityData[];
    hardwareProfile?: ServerUpdateBodyHardwareData | null;
    licenseContacts?: {
        [userId: string]: {
            country?: string;
            aliases: {
                [aliasId: string]: {
                    alias: string | null;
                    aliasType: string;
                };
            };
        };
    };
}

const optionalField = (key: string, value: unknown) => {
    return value !== undefined ? { [key]: value || null } : {};
};

export const getUpdateServerBody = (values: ServerUpdateFormData) => {
    const hardwareProfile: ServerUpdateBodyHardwareData = Object.entries(
        values.hardwareProfile ?? {}
    ).reduce((profile, [key, value]) => {
        return { ...profile, [key]: value || null };
    }, {});

    const community: ServerUpdateBodyCommunityData = {
        ...(values.communityName ? { name: values.communityName } : {}),
        ...(values.communityUrl ? { url: values.communityUrl } : {}),
        ...optionalField("size", values.communitySize),
        ...optionalField("tag", values.communityTag)
    };

    const contacts = values.contacts?.reduce((acc, val) => {
        const aliases = {
            ...(val.nameAliasId
                ? {
                      [val.nameAliasId]: {
                          alias: val.name,
                          aliasType: aliasType.name
                      }
                  }
                : {}),
            ...(val.emailAliasId
                ? {
                      [val.emailAliasId]: {
                          alias: val.email,
                          aliasType: aliasType.email
                      }
                  }
                : {}),
            ...(val.forumAliasId
                ? {
                      [val.forumAliasId]: val.forumName
                          ? {
                                alias: val.forumName,
                                aliasType: aliasType.forum
                            }
                          : null
                  }
                : {}),
            ...(val.discordAliasId
                ? {
                      [val.discordAliasId]: val.discordName
                          ? {
                                alias: val.discordName,
                                aliasType: aliasType.discord
                            }
                          : null
                  }
                : {}),
            ...(val.steamAliasId
                ? {
                      [val.steamAliasId]: {
                          alias: val.steamId,
                          aliasType: aliasType.steam
                      }
                  }
                : {})
        };

        const contactInfo = {
            ...optionalField("country", val.country),
            ...(Object.keys(aliases).length > 0 ? { aliases } : {})
        };

        return {
            ...acc,
            ...(Object.keys(contactInfo).length > 0
                ? {
                      [`${val.id}`]: contactInfo
                  }
                : {})
        };
    }, {});

    const result: ServerUpdateBodyData = {
        ...(values.serverName ? { serverName: values.serverName } : {}),
        ...(values.serverPlayerSlots
            ? { serverPlayerSlots: values.serverPlayerSlots }
            : {}),
        ...(values.availableAdmins
            ? { availableAdmins: values.availableAdmins }
            : {}),
        ...(values.adminAvailability
            ? {
                  adminAvailability: values.adminAvailability.times.map((t) => {
                      const start = DateTime.fromISO(t.startTime);

                      return {
                          startTime: start.toLocaleString(
                              DateTime.TIME_24_SIMPLE
                          ),
                          duration: Math.floor(
                              t?.endTime
                                  ? getDuration(
                                        start,
                                        DateTime.fromISO(t?.endTime)
                                    ).as("hours")
                                  : t.duration
                          ),
                          weekends: t.weekends,
                          weekdays: t.weekdays
                      };
                  })
              }
            : {}),
        ...(values.ipAddress ? { ipAddress: values.ipAddress } : {}),
        ...(values.locales ? { locales: values.locales } : {}),
        ...(values.serverProviderId
            ? { serverProviderId: values.serverProviderId }
            : {}),
        ...(values.locales ? { locales: values.locales } : {}),
        ...(Object.keys(community).length > 0 ? { community } : {}),

        ...("hardwareProfile" in values && values.hardwareProfile === null
            ? { hardwareProfile: null }
            : {}),
        ...(Object.keys(hardwareProfile).length > 0 ? { hardwareProfile } : {}),
        ...(contacts && Object.keys(contacts).length > 0
            ? { licenseContacts: contacts }
            : {}),
        ...optionalField("additionalComments", values.additionalComments)
    };

    return result;
};

export const diffFormData = <T extends Record<string, unknown>>(
    update: T,
    initial: T
) =>
    Object.fromEntries(
        Object.entries(update).filter(([key, value]) => {
            return initial[key] !== value;
        })
    );

export const diffContactFormData = (
    update: ServerUpdateContactFormData,
    initial?: ServerUpdateContactFormData
) => {
    const keyMap = {
        name: "nameAliasId",
        email: "emailAliasId",
        forumName: "forumAliasId",
        discordName: "discordAliasId",
        steamId: "steamAliasId"
    };

    if (!initial) {
        return update;
    }

    return Object.entries(update).reduce(
        (diff, [key, value]) => {
            const hasChange =
                value !== initial[key as keyof ServerUpdateContactFormData];

            if (hasChange) {
                return {
                    ...diff,
                    ...{
                        [keyMap[key as keyof typeof keyMap]]:
                            update[
                                keyMap[
                                    key as keyof typeof keyMap
                                ] as keyof ServerUpdateContactFormData
                            ],
                        [key]: value
                    }
                };
            }
            return { ...diff };
        },
        {
            id: update.id
        }
    );
};

export const updateServer = createAsyncThunk<
    ServerData,
    ServerUpdateFormData,
    { rejectValue: AppError }
>(
    `${namespace}/updateServer`,
    async (values: ServerUpdateFormData, { rejectWithValue }) => {
        const body: ServerUpdateBodyData = getUpdateServerBody(values);
        try {
            return await api.patch<ServerUpdateBodyData, ServerData>(
                endpoints.server.patch(values.id),
                body
            );
        } catch (error) {
            return rejectWithValue(error as AppError);
        }
    }
);

export const getQueryBody = (values: SearchQuery | null) => {
    return {
        ...(values?.ipAddress ? { ipAddress: values?.ipAddress } : {}),
        ...(values?.contactEmail ? { contactEmail: values?.contactEmail } : {}),
        ...(values?.licenseNumbers
            ? { licenseNumbers: [values?.licenseNumbers] }
            : {}),
        ...(values?.licenseStatuses?.length
            ? { licenseStatuses: values?.licenseStatuses }
            : {}),
        ...(values?.contactName ? { contactName: values?.contactName } : {}),
        ...(values?.communityName
            ? { communityName: values?.communityName }
            : {}),
        ...(values?.contactSteamId?.length
            ? { contactSteamId: values?.contactSteamId }
            : {}),
        ...(values?.gameIds?.length ? { gameIds: values?.gameIds } : {}),
        ...(values?.limit ? { limit: values.limit } : {}),
        ...(values?.offset ? { offset: values.offset } : {})
    };
};

export const search = createAsyncThunk<
    SearchData[],
    SearchQuery | null,
    { rejectValue: AppError }
>(
    `${namespace}/search`,
    async (values: SearchQuery | null, { rejectWithValue, dispatch }) => {
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        dispatch(setSearchQuery(values || {}));
        const query: SearchQueryBody = getQueryBody(values);
        try {
            return await api.post<SearchQueryBody, SearchData[]>(
                endpoints.server.search,
                query
            );
        } catch (error) {
            return rejectWithValue(error as AppError);
        }
    }
);

interface TempContact {
    email: string;
    name: string;
    discordName: string;
    steamId: string;
}

export const reduceAlias: (data: Contact) => ContactData = (data) => {
    const reducedAliases = data.aliases.reduce<Record<string, string>>(
        (accumulator, item) => {
            /* eslint-disable no-param-reassign */
            if (item.aliasType === aliasType.email) {
                accumulator.email = item.alias;
                accumulator.emailAliasId = item.id;
            }
            if (item.aliasType === aliasType.name) {
                accumulator.name = item.alias;
                accumulator.nameAliasId = item.id;
            }
            if (item.aliasType === aliasType.discord) {
                accumulator.discordName = item.alias;
                accumulator.discordAliasId = item.id;
            }
            if (item.aliasType === aliasType.steam) {
                accumulator.steamId = item.alias;
                accumulator.steamAliasId = item.id;
            }
            if (item.aliasType === aliasType.forum) {
                accumulator.forumName = item.alias;
                accumulator.forumAliasId = item.id;
            }
            /* eslint-enable no-param-reassign */
            return accumulator;
        },
        {}
    );

    const result: ContactData = {
        id: data.id,
        country: data.country,
        isPrimary: data.primaryContact,
        ...(reducedAliases as unknown as TempContact)
    };
    return result;
};

export const getContactProperty = (contacts: Contact[], property: string) => {
    if (!contacts || !contacts.length) {
        return "";
    }
    const result = contacts
        .filter((item) => item.primaryContact)
        .map((item) =>
            item.aliases.find((alias) => alias.aliasType === property)
        );
    return result[0] ? result[0].alias : "";
};

export const compress: (data: SearchData[]) => CompressedSearchData[] = (
    data
) => {
    const result: Array<CompressedSearchData> = [];
    if (!data || !data.length) {
        return result;
    }
    data.forEach((item) => {
        result.push({
            communityName: item.community.name,
            contactEmail: getContactProperty(
                item.license.contacts,
                aliasType.email
            ),
            contactName: getContactProperty(
                item.license.contacts,
                aliasType.name
            ),
            game: item.license.game.name,
            id: item.id,
            licenseNumber: item.license.licenseNumber,
            ipAddress: item.ipAddress,
            name: item.name,
            licenseId: item.license.id,
            status: item.license.status,
            steamId: getContactProperty(item.license.contacts, aliasType.steam)
        });
    });
    return result;
};

export const sortList: (
    data: CompressedSearchData[],
    property?: string,
    descending?: boolean
) => CompressedSearchData[] = (
    data,
    property = sortProperty.licenseNumber,
    descending = false
) => {
    return data.sort((a, b) => {
        const prepType = (obj: CompressedSearchData) =>
            typeof obj[property] === "string"
                ? obj[property].toLocaleString().toLocaleLowerCase()
                : obj[property];
        const first = prepType(a);
        const second = prepType(b);
        if (descending) {
            return first > second ? -1 : 1;
        }
        return first < second ? -1 : 1;
    });
};

export const getDateTimeAvailability = (
    data: AdminAvailabilityData[]
): DateTimeAvailability[] => {
    const result: DateTimeAvailability[] = [];
    data.forEach((item) => {
        const { weekends, weekdays, duration: durationInt } = item;
        const [hours] = item.startTime.split(":");
        const startTime = DateTime.now()
            .startOf("day")
            .plus({
                hours: parseInt(hours, radix)
            });
        const endTime = startTime.plus({ hours: durationInt });
        const duration = getDuration(startTime, endTime);
        result.push({
            startTime,
            endTime,
            duration,
            weekends,
            weekdays
        });
    });
    return result;
};

export const setServerDetails = (state: Server, data: ServerData) => {
    state.generalInfo = {
        gameId: data.license.game.id,
        gameName: data.license.game.name,
        serverProviderId: data.serverProvider.id,
        serverProviderName: data.serverProvider.name,
        name: data.name,
        ipAddress: data.ipAddress,
        locales: data.locales,
        playerSlots: data.playerSlots,
        availableAdmins: data.availableAdmins,
        additionalComments: data.additionalComments
    } as GeneralData;
    state.license = {
        id: data.license.id,
        lastModifiedBy: data.license.lastModifiedBy,
        lastModifiedTimestamp: data.license.lastModifiedTimestamp,
        licenseNumber: data.license.licenseNumber,
        publicKey: data.license.publicKey,
        serverName: data.name,
        status: data.license.status,
        statusReason: data.license.statusReason
    } as LicenseCardData;
    if (data.hardwareProfile) {
        state.hardwareProfile = {
            operatingSystem: data.hardwareProfile?.operatingSystem || "",
            cpuModel: data.hardwareProfile?.cpuModel || "",
            cpuFrequency: data.hardwareProfile?.cpuFrequency || "",
            physicalMemory: data.hardwareProfile?.physicalMemory || "",
            diskDrives: data.hardwareProfile?.diskDrives || "",
            networkSpeed: data.hardwareProfile?.networkSpeed || "",
            location: data.hardwareProfile?.location || "",
            hostingCompany: data.hardwareProfile?.hostingCompany || ""
        } as HardwareData;
    } else {
        state.hardwareProfile = null;
    }
    state.community = { ...data.community };
    state.adminAvailability = [...data.adminAvailability];
    const primary = data.license.contacts.find((item) => item.primaryContact);
    if (primary) {
        state.primaryContact = reduceAlias(primary);
    }
    const nonPrimary = data.license.contacts.filter(
        (item) => !item.primaryContact
    );
    if (nonPrimary && nonPrimary.length > 0) {
        state.additionalContacts = nonPrimary.map((item) => reduceAlias(item));
    }
};

const serverSlice = createSlice({
    name: namespace,
    initialState,
    reducers: {
        sort(state, { payload }: { payload: LicenseSort }) {
            state.sort = payload;
            state.list = sortList(
                state.list,
                payload.property,
                payload.descending
            );
        },
        setSearchQuery(state, { payload }: { payload: SearchQuery }) {
            state.searchQuery = payload;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(search.pending, (state) => {
            state.loading = httpStatus.pending;
            state.single = null;
            state.error = null;
        });
        builder.addCase(search.fulfilled, (state, { payload }) => {
            state.loading = httpStatus.fulfilled;
            let data = compress(payload);
            if (state.searchQuery?.offset && state.searchQuery?.offset > 0) {
                data = state.list.concat(data);
            }
            state.list = sortList(
                data,
                state.sort.property,
                state.sort.descending
            );
            state.error = null;
        });
        builder.addCase(search.rejected, (state, { payload }) => {
            state.loading = httpStatus.rejected;
            state.error = payload as AppError;
        });

        builder.addCase(getServer.pending, (state) => {
            state.loading = httpStatus.pending;
            state.single = null;
            state.error = null;
        });
        builder.addCase(getServer.fulfilled, (state, { payload }) => {
            state.loading = httpStatus.fulfilled;
            state.error = null;
            state.single = payload as ServerData;
            setServerDetails(state, payload);
        });
        builder.addCase(getServer.rejected, (state, { payload }) => {
            state.loading = httpStatus.rejected;
            state.error = payload as AppError;
        });

        builder.addCase(updateServer.fulfilled, (state, { payload }) => {
            state.loading = httpStatus.fulfilled;
            state.error = null;
            state.single = payload as ServerData;
            setServerDetails(state, payload);
        });
        builder.addCase(updateServer.rejected, (state, { payload }) => {
            state.loading = httpStatus.rejected;
            state.error = payload as AppError;
        });
        builder.addCase(updateServer.pending, (state) => {
            state.loading = httpStatus.pending;
            state.single = null;
            state.error = null;
        });
    }
});

export const { sort, setSearchQuery } = serverSlice.actions;
export default serverSlice.reducer;
